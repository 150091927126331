import React from "react"


export default function Body() {
    return (
    <main className="body"> 
    <figure title="geometric" className="headerphoto"></figure>
      
        </main>
           );
        }
      